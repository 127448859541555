import { useEffect } from "react";
import { Card, Container, Row } from "react-bootstrap";

function Rpass(props) {

    let cards = document.getElementsByClassName("PageCard");
    let links = document.getElementsByClassName("contactLink");

    useEffect(() => {
        for (let i = 0; i < cards.length; i++) {
            cards[i].style = props.cardColor;
            if (links[i]) {
                links[i].style = props.textColor;
            }
        }
    })

    return (
        <>
            <Container>
                <Row>
                    <h1>Rpass</h1>
                    <hr />
                    <p>
                        Github: <a target="_blank" href="https://github.com/RylanTech/Local-Reactive-Pass">https://github.com/RylanTech/Local-Reactive-Pass</a> - Frontend & Backend
                    </p>
                    <div className="col-md-2" />
                    <Card className="PageCard col-12 col-md-8 youtube-video-card">
                        <Card.Body>
                            <Card.Header>What is Reactive Pass and how to deploy it</Card.Header>
                            <Card.Text>
                                <iframe className="col-12 youtube-video" src="https://www.youtube.com/embed/LIsWYkacBVE?si=-r6bqbm3Di_soDiB" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <div className="col-md-2" />
                    <h3>What is Rpass?</h3>
                    <p>
                        Rpass is a password manager that I initially created for myself to run on my local network for maximum security. However, I liked my work on it so much that I decided to publish it on <a target="_blank" href="https://reactivepass.com">https://reactivepass.com</a> and it is available for $8 a year. While having it on the WAN is convenient, it’s not as secure, so I recommend hosting it on a local network and accessing it through a VPN.
                    </p>
                    <h3>What does the user experience?</h3>
                    <p>
                        Once the user creates an account with their name, email and password and the user log’s in, they are prompted to set up 2FA, although it isn’t a requirement to complete the setup. Then the user can create a “pass” to store their password information about their online account. All of the information they input is encrypted and saved. Once the user needs to access their pass, they input their master password, which is then unencrypted and delivered to them.
                    </p>
                    <Card className="PageCard col-12 youtube-video-card">
                        <Card.Body>
                            <Card.Header>"Pass" refers to the complete record of the encrypted account information</Card.Header>
                            <Card.Text>
                                <Card.Img className="eduPhoto" src="/images/rpass-account-functions.png" />
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <h3>The Database</h3>
                    <p>
                        Technologies
                        <div className="technologies">
                            MySQL
                        </div>
                        <div className="technologies">
                            Deployed on Linode
                        </div>
                        <br />
                        The database is written in MySQL and it's running on the same server as the backend.
                        The payments and logs table is absent from the local version of Rpass because they are designed to keep track of stripe payments which isn't in the local version anyway.
                    </p>
                    <Card className="PageCard col-12 youtube-video-card">
                        <Card.Body>
                            <Card.Header>Database Diagram</Card.Header>
                            <Card.Text>
                                <Card.Img className="eduPhoto" src="/images/rpass-db.png" />
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    <p>
                        <div className="highlighted-text">
                            When the user's passes are stored in the database, it's encrypted with the help of user's password. This means that not even the admin who has access to the database can see the unencrypted passes.
                        </div>
                    </p>
                    <h3>The Backend</h3>
                    <p>
                        Technologies
                        <div className="technologies">
                            REST API
                        </div>
                        <div className="technologies">
                            Written in Express
                        </div>
                        <div className="technologies">
                            Running on Node.js
                        </div>
                        <div className="technologies">
                            Bcrypt - SHA-256 Encryption
                        </div>
                        <div className="technologies">
                            Passport - Authentication
                        </div>
                        <div className="technologies">
                            Deployed on Linode
                        </div>
                    </p>
                    <Card className="PageCard col-12 youtube-video-card">
                        <Card.Body>
                            <Card.Header>Backend Flowchart</Card.Header>
                            <Card.Text>
                                <Card.Img className="eduPhoto" src="/images/rpass-backend.png" />
                            </Card.Text>
                        </Card.Body>
                    </Card>

                    <h3>The Frontend</h3>
                    <p>
                        Technologies
                        <div className="technologies">
                            Written in React
                        </div>
                        <div className="technologies">
                            Client side rendered
                        </div>
                        <div className="technologies">
                            Uses localstorage to store JWT key
                        </div>
                        <div className="technologies">
                            Populates information dynamically based on API communication with the backend
                        </div>
                        <div className="technologies">
                            Deployed on Hostinger
                        </div>
                    </p>
                    <Card className="PageCard col-12 youtube-video-card">
                        <Card.Body>
                            <Card.Text>
                                <Card.Img className="eduPhoto" src="/images/Rpass.png" />
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Row>
            </Container>
        </>
    )
}
export default Rpass